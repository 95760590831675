<template>
  <div>
    <section-banner :model="section"></section-banner>
    <v-container>
      <base-loader :isLoading="isLoading"></base-loader>
    </v-container>
  </div>
</template>

<script>
import SectionBanner from "@/components/Frontend/SectionBanner";
  export default {
    components: {
    SectionBanner
    },
    data() {
      return {
        isLoading: false,
        section: {
          BannerSmall: true,
          Title: "Order Cancelled",
          Subtitle: "Your order was cancelled or payment failed",
          HTMLDescription: `<h3 class="mar30top">Please try again.</h3>
          <h4 class="mar30top">Or contact us for further assistance <a class="download__link" href="/contact"> <strong>HERE</strong> </a>.</h4>`,
          img: require("@/assets/img/background.jpg"),
        }
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>